.ctaBlock {
	border-radius: $global-radius;
	background: $primary-color;
	color: $white;
	padding: rem-calc(15);
	margin-top: rem-calc(30);
	margin-bottom: rem-calc(-60);
	position: relative;
	z-index:3;
	@include xy-grid;
	@include breakpoint(large) {
		margin-top: rem-calc(60);
		padding: rem-calc(30);
	}
	h2 {
		margin-top: 0;
	}
	h2,figcaption {
		color: $tint-gold;
	}
	.button {
		background-color: $tint-gold;
		color: $primary-dark;
		&:hover {
			background-color: $light-gold;
		}
	}
}
.ctaBlock:last-child {
	margin-bottom: rem-calc(30);
}
.ctaBlock.green {
	background: $dark-green;
	.button {
		color: $darker-green;
	}
}
.ctaBlock.darkTan {
	color: $text-gray;
	background: $light-gold;
	h2 {
		color: $primary-dark;
	}
	.button {
		background: $primary-dark;
		color: $white;
		&:hover {
			background: $secondary-color;
		}
	}
}
.cta-content {
	@include xy-cell(12);
	@include breakpoint(large) {
		@include xy-cell(auto);		
	}
}
.cta-image {
	@include xy-cell(12);
	@include breakpoint(large) {
		@include xy-cell-size(5,0,none);
    	margin-top: rem-calc(-60);
	}
	img {
		border-radius: $global-radius;
	}
}	
.ctaBlock.left .cta-image {
	@include breakpoint(large) {
		@include flex-order(1);	
	}
}
.ctaBlock.left .cta-content {
	@include breakpoint(large) {
		@include flex-order(2);	
	}
}
.ctaBlock + * {
	padding-top: rem-calc(90)!important;
}
