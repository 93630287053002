.subnav-wrapper {
	margin: rem-calc(0 20);
}
.breadcrumbs,
.menu.subnav {
	font-family: $header-font-family;
	position:relative;
	z-index: 5;
	max-width: rem-calc(1400);
	margin: 0 auto rem-calc(-30);
	background: $tint-gold;
	border-radius: $global-radius;
	padding: rem-calc(7);
	li a {
		margin: 0;
		padding: rem-calc(12 16);
		font-weight: normal;
		&:hover {
			color: $primary-dark;
		}
	}
	li.active a {
		color: $primary-dark;
		background: $white;
		border-radius: $global-radius;
	}
}

.breadcrumbs + .main-content,
.menu.subnav + .main-content {
	padding-top: rem-calc(30);
}