.accordion {
	padding: 0;
	background: transparent;
}

:last-child:not(.is-active)>.accordion-title,
.accordion-title {
	border-radius: $global-radius!important;
}



.accordion-title {
	@include breakpoint(medium) {
		font-size: rem-calc(18);
	}
	@include breakpoint(large) {
		font-size: rem-calc(24);
	}
}
.accordion-title:hover,
.accordion-title:focus {
	background-color: $tint-gold;
	color: $primary-dark;
}
.accordion .accordion-item {
	border: 1px solid $light-gold;
	border-radius: $global-radius;
	margin-bottom: rem-calc(7);
}
.accordion-item:first-child>:first-child {
	border-radius: 0;
}
.accordion-item:last-child>:last-child {
	border-radius: 0;
}

.bg-goldTint .accordion .accordion-item {
	border-color: $light-gold;
}
.bg-goldTint .accordion-title:hover,
.bg-goldTint .accordion-title:focus {
	background-color: $lighter-gold;
}
.accordion-content {
	background: transparent;
}