// FOOTER

footer {
	text-align: left;
	a {
		color: $tint-gold;
		text-decoration: underline;
		&:hover {
			color: rgba($tint-gold,.8);
			text-decoration: none;
		}
	}
	a.button {
		text-decoration: none;
	}
}

.footer-newsletter {
	background: $primary-dark;
	color: $white;
	padding: rem-calc(30 0);
	@include breakpoint(large) {
		padding: rem-calc(60 0);
	}
	.inner {
		max-width: rem-calc(1400);
		margin: 0 auto;
		display: block; 
		text-align: center;
	}
	h1,h2,h3,h4,h5,h6 {
		color: $light-gold;
		margin-bottom: rem-calc(15);
	}
	label {
		color: $white;
	}

	.submit-buttons {
		max-width: rem-calc(1200);
		padding-inline: rem-calc(15);
	}
}



.footer-main,
.footer-meta,
.footer-copyright {
	color: $white;
	p { 
		font-size: rem-calc(16);
	}
	h1,h2,h3,h4,h5,h6 {
		color: $tint-gold;
	}
	.fa-brands {
		font-size: 150%;
		padding: rem-calc(7);
	}
}
.footer-main .logos p {
	font-size: rem-calc(20);
	line-height: 1.2;
}
.footer-map-contact {
	@include xy-grid;
	@include flex-align(left, stretch);
}
.footer-map {
	background: $tint-green;
	flex: 0 0 auto;
	margin: 0;
	width: 100%;
	min-height: rem-calc(300);
	position: relative;
	overflow: hidden;
	@include flex;
	@include flex-align(center, stretch);
	@include breakpoint(large) {
		width: 50%;
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
		filter:saturate(.5);
	}	
	.map-button {
		position: absolute;
		z-index:3;
		bottom: rem-calc(30);
		right: rem-calc(60);
	}
}
.footer-contact {
	flex: 0 0 auto;
	width: 100%;
	margin: 0;
	background: $tint-green;
	@include breakpoint(large) {
		width: 50%;	
	}
	.footer-form {
		margin: rem-calc(30 0 30 30);
		max-width: rem-calc(810);
	}
}	


.footer-main {
	background: $primary-light;
	padding: rem-calc(30 0);
	@include breakpoint(large) {
		padding: rem-calc(60 0);
	}
	.inner {
		max-width: rem-calc(1400);
		margin: 0 auto;
	}
	.logos {
		@include xy-cell(12);
		@include breakpoint(medium) {
			@include xy-cell(4);
		}
		@include breakpoint(large) {
			@include xy-cell(2);
		}
	}
	.footer-text {
		@include xy-cell(12);
		@include breakpoint(medium) {
			@include xy-cell(8);
		}
		@include breakpoint(large) {
			@include xy-cell(9);
			@include xy-cell-offset(1);
			text-align: center;
			}
		}
		text-align: center;
		.menu {
			margin-bottom: rem-calc(30);
			a {
				color: $white;
				&:hover {
					color: rgba($white,.7);
					text-decoration: underline;
				}
			}
		}
	}
.icons {
	@include xy-cell(shrink);
	position:relative;
	list-style:none;
	display:flex;
	svg {
		width: 100%;
	}
	li {
		max-width: rem-calc(40);
	}
	li + li {
		margin-left: rem-calc(30);
	}
}



