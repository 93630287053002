.grecaptcha-badge { 
	visibility: hidden; 
}


.subscribe-columns {
	@include xy-grid;
	@include flex-align(left,bottom);
	margin: 0 auto;
	text-align: left;
	color: $white;
	label {
		color: $white;
	}
}
.subscribe-column {
	@include xy-cell(auto);
}
.subscribe-column[data-freeform-controls] {
	@include xy-cell(shrink);
}
.subscribe-column .button {
	margin-bottom: 0;
}

[data-freeform-recaptcha-container],
[data-freeform-hcaptcha-container] {
	display: none;
}

.button.primary {
	background-color: $button-background;
	&:hover,&:active {
		background-color: scale-color($button-background, $lightness: -25%);
	}
}
