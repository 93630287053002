.terracesEvents {
	border-top: 4px solid $dark-gold;
	padding-top: rem-calc(30);
	margin-top: rem-calc(30);
	h2 {
		text-align: center;
	}	
	h3 {
		margin-bottom: rem-calc(7);
	}
	.news-listing {
		margin-top: rem-calc(30);
		max-width: rem-calc(1000);
		margin: 0 auto;
	}
	.news-blurb {
		@include xy-grid;
		margin-bottom: rem-calc(30);
		border-bottom: 0;
		padding: rem-calc(15 5);
		background: $tint-gold;
		border-radius: $global-radius;
	}	
	.button {
		margin-bottom: 0;
	}

	
}
