// IMAGE GALLERY
.image-gallery {
	max-width: rem-calc(1440);
	margin: 0 auto;
	@include xy-grid;
	@include xy-grid-layout(3, '.gallery-image');
	@include breakpoint(medium) {
		@include xy-grid-layout(4, '.gallery-image');	
	}
	@include breakpoint(xlarge) {
		@include xy-grid-layout(5, '.gallery-image');	
	}
}
.gallery-image {
	margin-block: rem-calc(10);
	overflow:hidden;
	border-radius: $global-radius;

	img {
		transition: all .2s ease-in-out;
		overflow: hidden;
		&:hover {
			transform: scale(1.1);
		}
	}
}
