

.cards .slide {
	max-width: rem-calc(1000);
	@include flex;
	@include flex-align-self(stretch);
}

.card img {
	width: 100%;
	max-width: auto;
	@include flex-align-self(stretch);
}
.card-section {
	@include breakpoint(large) {
		padding: rem-calc(30);
	}
}
.card-section h3 {
	font-size: rem-calc(28);
	@include breakpoint(medium) {
		font-size: rem-calc(32);
	}
}
.card-section .button {
	margin-bottom: 0;
}
.display-grid {
	@include flex-align(center, stretch);
	@include xy-grid;
	@include xy-grid-layout(3, '.slide')
}

.display-grid.cards.up-2 {
	@include xy-grid-layout(2, '.slide')
}
.display-grid.cards.up-3 {
	@include xy-grid-layout(3, '.slide')
}
.display-grid.cards.up-4 {
	@include xy-grid-layout(4, '.slide')
}
.display-grid.cards.up-5 {
	@include xy-grid-layout(5, '.slide')
}


.display-rows .card {
	@include xy-grid;	
	max-width: rem-calc(1400);
	margin: 0 auto rem-calc(30);
}
.display-rows .card a {
	@include xy-grid;
}
.display-rows .card-image {
	@include breakpoint(medium) {
		@include xy-cell(4);
		margin-left: 0;
		margin-right: 1.25rem;
		display:flex;
		@include flex-align(right, stretch);
		img {
			object-fit: cover;
			height: 100%;
		}	
	}
}
.display-rows .card-section {
	@include breakpoint(medium) {
		@include xy-cell(8);
		@include flex-align-self(middle);
	}
}


.card-section.promoBox {
	text-align:center;
	background: $primary-color;
	color: $white;
	@include breakpoint(xlarge) {
		padding: rem-calc(30 60);
	}
	h1,h2,h3,h4,h5,h6,a {
		color: $white;
	}
	a {
		color: white; 
		text-decoration:underline;
		&:hover {
			color: rgba($white,.8);
		}
	}
	a.button {
		background: $white;
		color: $primary-dark;
		text-decoration: none;
		margin: rem-calc(0 15);
		&:hover {
			background: rgba($white,.7);
		}
	}
}

.card:hover  {
	background: $tint-green;
}

.display-scroll {
	display: flex;
	gap: rem-calc(30);
	overflow-x: auto;
	scroll-snap-type: x mandatory;

	--offset-width: calc(((100% - (min(1400px, 100%) + (30px * 2))) /2) + (30px * 2) );
	padding-inline: var(--offset-width);
	scroll-padding-inline-start: var(--offset-width);
	
	> * {
		flex: 0 0 rem-calc(400);
		scroll-snap-align: start;
	}
}

