.hero {
	position: relative;
	z-index: 2;
	padding: 0;
	width: 100%;
	max-width: rem-calc(1400);
	margin: 0 auto;
	@include xy-grid;
	@include flex-align(center, stretch);
	
	img {
		border-radius: 0;
	}
}
.hero-left {
	flex: 0 0 auto;
	margin: 0;
	width: 100%;
	@include xy-cell(12);
	@include breakpoint(medium) {
		img {
			object-fit: cover;
		}	
	}
	@include breakpoint(large) {
		@include xy-cell(6);	
	}
}
.hero .hero-slideshow,
.hero .orbit-wrapper,
.hero .orbit-container,
.hero .orbit-slide,
.hero .orbit-slide figure,
.hero .orbit-slide figure img {
	width: 100%;
	  height: 100%;
	  object-fit: cover;
	  min-height: rem-calc(250);
	  @include breakpoint(medium) {
	  	min-height: rem-calc(400);
	  }
	  @include breakpoint(large) {
		min-height: rem-calc(450);		  
	  }
	  @include breakpoint(xlarge) {
		min-height: rem-calc(500);  
	  }
	  @include breakpoint(xxlarge) {
		  min-height: rem-calc(600);  
		}
}
.hero-right {
	@include xy-cell(12);
	
	@include flex-align-self(middle);
	.inner {
		margin: rem-calc(30 30 0 30);
		max-width: rem-calc(810);
		h1 {
			width: 100%;
			margin-bottom: .5em;
		}
		* {
			width: 100%;
		}
		p:last-child {
			margin-bottom: 0;
		}
	}
	@include breakpoint(large) {
		@include xy-cell(6);
	}
}	
.hero.hasSubnav .hero-right {
	padding-top: rem-calc(30);
}
.hero-slideshow {
	position: relative;
	max-width: rem-calc(1400);
	margin: 0 auto;	
	border-radius: $global-radius;
	overflow: hidden;
}
.hero-slideshow.full {
	width: 100%;
	max-width: 100%;
}

.hero-slideshow img {
	width: 100% !important;
}
.hero-slideshow .headline {
	font-family: $header-font-family;
	font-size: 7vw;
	color: $white;
	line-height: 1.0;
	text-align: center;
	padding: rem-calc(60);
	position: absolute;
	width: 100%;
	top: 50%;
	transform: translateY(-50%);
	text-shadow:0px 0px 18px #000000;
	z-index:1;
	@include breakpoint(medium) {
		font-size: 5vw;	
	}
	@include breakpoint(large) {
		font-size: 3vw;	
	}	
}
.orbit-figure figcaption {
	position: absolute;
	bottom: 40%;
	left: 10%;
	padding: rem-calc(15 30 0 30);
	color: #fff;
	@include breakpoint(small down){
		bottom: 50%;
		left: 1%;
	}
	@include breakpoint(medium down){
		bottom: 40%;
		left: 1%;
	}
}
.orbit-figure.darken img {
	filter: brightness(70%);
}




.__home__ .hero{
	.hero-right {
		align-self: start;
		.inner {
			margin-bottom: rem-calc(60);
		}
	}
}
