.bg-white {
	background: $white;
}
.bg-goldTint {
	background: $tint-gold;
}
.bg-greenTint {
	background: $tint-green;
}
.pad-large {
	padding-block: rem-calc(60);
}
.pad-xlarge {
	padding-block: rem-calc(90);
}
.pad-small {
	padding-block: rem-calc(30);
}