h3 + .image-copy,
h2 + .image-copy,
.image-copy + .image-copy {
	margin-top: rem-calc(30);
}

.inner {
	max-width: rem-calc(1400);
	margin: 0 auto;
	@include xy-grid;
}
.block-image {
	margin-bottom: rem-calc(30);
	@include xy-cell(12);
	@include breakpoint(large) {
		@include xy-cell(6);	
	}
	img {
		border-radius: $global-radius;
	}
}
.block-content {
	@include xy-cell(12);
	@include breakpoint(large) {
		@include xy-cell(6);	
		@include flex-align-self(middle);
	}
}
.image-right .block-image {
	@include breakpoint(large) {
		@include flex-order(2);	
	}
}
.image-right .block-content {
	@include breakpoint(large) {
		@include flex-order(1);
	}
}