.floorplans {
	@include xy-grid;
	max-width: rem-calc(1400);
	margin: 0 auto;
	@include breakpoint(medium) {
		@include xy-grid-layout(2, '.floorplan');		
	}
	@include breakpoint(large) {
		@include xy-grid-layout(3, '.floorplan');		
	}
	@include breakpoint(xlarge) {
		@include xy-grid-layout(4, '.floorplan');		
	}
}
.floorplan {
	overflow: hidden;
	text-align: center;
	background: rgba($tint-gold,.3);
	border-radius: $global-radius;
	margin-bottom: rem-calc(30);
	h3 {
		margin-top: rem-calc(30);
		margin-bottom: rem-calc(7);
	}
	p {
		line-height: 1.2;
	}
}
.floorplan-content {
	padding-bottom: rem-calc(15);
}
.fancybox-content img {
	background: #fff;
}

