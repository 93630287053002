.bios {
	max-width: rem-calc(1440);
	margin: 0 auto;
}
.bios.display-cards {
	@include flex-align(center, stretch);
	@include xy-grid;
	@include xy-grid-layout(3, '.bio');
	text-align: center;
	.bio {
		margin-bottom: rem-calc(30);
	}
	.bio-image {
		margin-bottom: rem-calc(7);
	}
}
	
.bios.display-list {
	max-width: rem-calc(1000);
	margin: 0 auto;
	@include breakpoint(medium) {
		column-count: 2;	
	}
}	
.bio-content {
	break-inside: avoid;	
}	
// BIOS

.bio-name {
	font-family: $header-font-family;
	margin-bottom: 0;
}
.reveal .bio-name {
	margin-bottom: rem-calc(7);
}
.bio-title {
	line-height: 1;
	font-size: 1em;
}
.bio a:hover p {
	color: $primary-light;
}

// REVEAL POPUP
.biography {
	@include xy-grid;
	.bio-image {
		@include xy-cell(4);
	}
	.bio-content {
		@include xy-cell(8);
	}
}