a[href$=".pdf"]::before {
	display: none;
}

.dining-menus {
	@include breakpoint(large) {
		@include flex-align(center, stretch);
		@include xy-grid;
		@include xy-grid-layout(2, '.menu-link')		
	}
}
.menu-link {
	margin-bottom: rem-calc(30);
	figure {
		border: 1px solid $medium-gray;
		overflow: hidden;
	}
	img {
		transition: all .2s ease-in-out;
		&:hover {
			transform: scale(1.05);
		}
	}
}