.bible-verse {
	background: $primary-dark url(/assets/uploads/images/almond-blossom-sketch.png) top center;
	padding-block: rem-calc(60);
	padding-inline: rem-calc(15);
	blockquote {
		text-align: center;
		max-width: rem-calc(1000);
		margin: 0 auto;
		p {
			font-size: rem-calc(24);
			color: $white;
			margin-bottom: rem-calc(15);
		}
		cite {
			color: $white;
			font-family: $body-font-family;
			font-style: normal;
			font-size: rem-calc(18);
			margin-bottom: 0;
		}
				
	}
}
.bible-verse + * {
	padding-top: rem-calc(60);
}


.hero + .main > .section > .section-inner > .bible-verse:first-child {
	margin-top: rem-calc(-90);
}



